import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function ProgrammingTest() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="4" />

                    </div>
                    <div className="w-3/4">
                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                        Questionnaire test
                        </h2>               
                        <br /><br />       
                        Skillmeter offers everything you need to create and assign customized assessments to specific candidates.
                        <br /><br />
                        <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/questionnaire-test.png" alt="" />
                        <br /><br />

                        <b>Timed tests</b>
                        <br />
                        Tests can be timed or not timed. If timed, you can choose the maximum amount of time you will allow candidates to complete a test.
                        <br /><br />

                        <b>Scored tests</b>
                        <br />
                        Tests can be scored or not scored. If scored, you can enter the minimum score a candidate should achieve to successfully pass the test.
                        <br /><br />

                        <b>Question pooling</b>
                        <br />
                        Tests can be set so that everyone receive the same questions or they can be pulled randomly from a larger pool of questions.
                        <br /><br />

                        <b>Skip questions</b>
                        <br />
                        You can configure the tests to allow candidates skip questions in order to answer them at the end of the session.
                        <br /><br />

                        <b>Review questions</b>
                        <br />
                        You can configure the tests to allow candidates flag questions in order to review them at the end of the session.
                        <br /><br />

                        <b>Public/private tests</b>
                        <br />
                        By default all tests you create are private which means only candidates you add can take the tests based on the PIN code they receive. However you can also create public tests (with public Url) that you can distribute via job ads or your website so that anyone can take it after they enter their basic information (name/email).


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}